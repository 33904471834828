<div class="mission-statistics">
  <h2 class="statistics-title">Statistiques des Missions</h2>
  <p class="statistics-total">Total des missions: <strong>{{ totalMissions }}</strong></p>
  
  <div class="statistics-grid">
    <div class="statistics-item">
      <span class="status-label">Prise en charge marchandise:</span> 
      {{ totalMissionsByStatus['Prise en charge marchandise'] }}
    </div>
    <div class="statistics-item">
      Marchandise livrée:
      {{ totalMissionsByStatus['Marchandise livrée'] }}
    </div>
    <div class="statistics-item">
      <span class="status-label">Revenu au parking:</span> 
     {{ totalMissionsByStatus['Revenu au parking'] }}
    </div>
    <div class="statistics-item">
      <span class="status-label">Mission démarrée:</span> 
      {{ totalMissionsByStatus['Mission démarrée'] }}
    </div>
    <div class="statistics-item">
    Mission créée: 
      {{ totalMissionsByStatus['Mission créée'] }}
    </div>
    <div class="statistics-item">
  Retourner au parking 
      {{ totalMissionsByStatus['Retourner au parking'] }}
   
    </div>
  </div>
</div>
<table>
  <thead>
    <tr>
      <th>#</th>
      <th>Date de Début</th>
      <th>Description</th>
      <th>Statut</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let mission of missions; let i = index">
      <td>{{ i + 1 }}</td>
      <td>{{ mission.dateDebut | date:'dd/MM/yyyy HH:mm' }}</td>
      <td>{{ mission.dateFin | date:'dd/MM/yyyy HH:mm' }}</td>
      <td>{{ mission.devis.villeDepart }}</td>
      <td>{{ mission.devis.villeArrivee }}</td>
      <td>{{ mission.devis.nomClient }}</td>
      <td>{{ mission.devis.telephoneClient }}</td>


    </tr>
  </tbody>
</table>
