<div class="container-fluid">
  <div class="row">
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark-blue">
      <a class="navbar-brand" href="#">Tableau de Bord</a>
    </nav>
  </div>

  <div class="row mt-5">
    <div class="col-md-3">
      <div class="card stat-card">
        <img src="/assets/img 2 dash.png" class="card-img-top" alt="Deliveries Icon">
        <div class="card-body text-center">
          <h5 class="card-title">Résumé des Livraisons</h5>
          <p><strong>{{ totalMissionsByStatus['Prise en charge marchandise'] }}</strong> Prise en charge marchandise</p>
          <p><strong>{{ totalMissionsByStatus['Marchandise livrée'] }}</strong> Marchandise livrée</p>
          <p><strong>{{ totalMissionsByStatus['Revenu au parking'] }}</strong> Revenu au parking</p>
          <p><strong>{{ totalMissionsByStatus['Mission démarrée'] }}</strong> Mission démarrée</p>
          <p><strong>{{ totalMissionsByStatus['Mission créée'] }}</strong> Mission créée</p>
          <p><strong>{{ totalMissionsByStatus['Retourner au parking'] }}</strong> etourner au parking</p>
        </div>
      </div>
    </div>

    <div class="col-md-3">
      <div class="card stat-card">
        <img src="/assets/img 1 dach.png" class="card-img-top" alt="Vehicles Icon">
        <div class="card-body text-center">
          <h5 class="card-title">Statistiques des Véhicules</h5>
          <p><strong>{{totalVehicules}}</strong> Total des Véhicules</p>
          <p><strong>{{vehiculesDisponibles}}</strong> En Service</p>
          <p><strong>{{vehiculesNonDisponibles}}</strong> En Maintenance</p>
        </div>
      </div>
    </div>

 
    <div class="col-md-3">
      <div class="card stat-card">
        <img src="/assets/img 3 dash .png" class="card-img-top" alt="Missions Icon">
        <div class="card-body text-center">
          <h5 class="card-title">Prochaines Missions</h5>
          <ul class="list-unstyled">
            <li *ngFor="let mission of missions; let i = index">
              <strong>Mission {{ i + 1 }}</strong><br>
              <small><strong>Début :</strong> {{ mission.dateDebut | date: 'dd/MM/yyyy HH:mm' }}</small><br>
              <small><strong>Fin :</strong> {{ mission.dateFin | date: 'dd/MM/yyyy HH:mm' }}</small><br>
              <small><strong>Départ :</strong> {{ mission.devis.villeDepart }}</small><br>
              <small><strong>Arrivée :</strong> {{ mission.devis.villeArrivee }}</small><br>
              <small><strong>Client :</strong> {{ mission.devis.nomClient }}</small><br>
              <small><strong>Téléphone :</strong> {{ mission.devis.telephoneClient }}</small>
              <hr *ngIf="i < missions.length - 1"> <!-- Séparateur entre les missions -->
            </li>
          </ul>
        </div>
      </div>
    </div>
    

    <div class="col-md-3">
      <div class="card stat-card">
        <img src="/assets/img 4 dash .png" class="card-img-top" alt="Notifications Icon">
        <div class="card-body text-center">
          <h5 class="card-title">Notifications Récentes</h5>
          <ul class="list-unstyled">
            <li *ngFor="let notification of recentNotifications">
              <p>{{notification.message}}</p>
              <small>{{notification.date |  date: 'dd/MM/yyyy HH:mm'}}</small>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-5">
    <div class="col-md-8">
      <div class="card task-card">
        <img src="/assets/img 5 dash.png" class="card-img-top" alt="Tasks Icon">
        <div class="card-body">
          <h5 class="card-title">Tâches en Attente</h5>
          <ul class="list-group list-group-flush">
            <li class="list-group-item d-flex justify-content-between align-items-center" *ngFor="let task of pendingTasks">
              <div>
                <h6>{{task.title}}</h6>
                <small>Priorité : {{task.priority}}</small>
              </div>
              <span class="badge badge-dark-blue">{{task.deadline}}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- Nouvelle colonne pour la liste des chauffeurs -->
 <!-- Nouvelle colonne pour la liste des chauffeurs -->
<div class="col-md-4">
  <div class="card chauffeurs-card">
    <div class="card-body">
      <h5 class="card-title">Liste des Chauffeurs</h5>
      <ul class="list-group list-group-flush">
        <li class="list-group-item d-flex align-items-center" *ngFor="let chauffeur of chauffeursList">
          <img *ngIf="!chauffeur.image" src="/assets/profile-avatar.png" class="rounded-circle me-3" alt="Image du chauffeur" width="50" height="50">
          <!-- Affichage de l'image encodée en Base64 -->
          <img *ngIf="chauffeur.image" [src]="'data:image/jpeg;base64,' + chauffeur.image" class="rounded-circle me-3" alt="Image du chauffeur" width="50" height="50">
          <div>
            <h6 class="mb-0">{{ chauffeur.nom }} {{ chauffeur.prenom }}</h6>
            <small [ngClass]="{'text-success': chauffeur.statut === 'Actif', 'text-warning': chauffeur.statut === 'En congé'}">
              {{ chauffeur.statut }}
            </small>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>


    <div class="col-md-3">
      <div class="card stat-card">
        <img src="/assets/img 6 dash.png" class="card-img-top" alt="Performance Icon">
        <div class="card-body text-center">
          <h5 class="card-title">Performance des Chauffeurs</h5>
          <p><strong>{{chauffeurPerformance.successfulDeliveries}}</strong> Livraisons Réussies</p>
          <p><strong>{{chauffeurPerformance.delays}}</strong> Retards</p>
          <p><strong>{{chauffeurPerformance.complaints}}</strong> Réclamations</p>
        </div>
      </div>
    </div>
    
    <div class="col-md-3 mt-2">
      <div class="card stat-card">
        <img src="/assets/img 7 dash.png" class="card-img-top" alt="Reports Icon">
        <div class="card-body text-center">
          <h5 class="card-title">Rapports et Analyses</h5>
          <p><strong>{{reportsGenerated}}</strong> Rapports Générés</p>
          <button class="btn btn-dark-blue" (click)="generateReport()">Générer un Rapport</button>
        </div>
      </div>
    </div>

    
    <div class="col-md-3 mt-2">
      <div class="card stat-card">
        <img src="/assets/img 8 dash.png" class="card-img-top" alt="Customer Engagement Icon">
        <div class="card-body text-center">
          <h5 class="card-title">Engagement des Clients</h5>
          <p><strong>{{customerSatisfaction}}</strong> Taux de Satisfaction</p>
          <p><strong>{{feedbackCount}}</strong> Retours Clients</p>
          <button class="btn btn-dark-blue" (click)="viewCustomerFeedback()">Voir les Retours</button>
        </div>
      </div>
    </div>
    
    <div class="col-md-3 mt-2">
      <div class="card stat-card">
        <img src="/assets/img 9 dash.png" class="card-img-top" alt="Panne Icon">
        <div class="card-body text-center">
          <h5 class="card-title">Déclarations de Pannes</h5>
          <p><strong>23</strong> Pannes Déclarées</p>
          <p><strong>05</strong> Pannes en Attente</p>
          <button class="btn btn-dark-blue">Voir les Détails</button>
        </div>
      </div>
    </div>
    
  </div>
</div>
