<div class="bar">
  <div class="partie">
    <img src="/assets/map.png" alt="">
    <h4>Rue Apollo XI, Tunis 1080</h4>
  </div>
  <div class="partie">
    <img src="/assets/mail.png" alt="">
    <h4>karim.gharbi@mss.tn</h4>
  </div>
  <div class="partie">
    <img src="/assets/Tel.png" alt="">
    <h4>(+216) 98 35 93 58</h4>
  </div>
</div>
<footer>
    <div class="content">
      <div class="top">
        <div class="logo-details">
            <img src="/assets/logo_footer.png" alt="Logo" />
        </div>
        <div class="media-icons">
          <a href="#"><i class="fab fa-facebook-f"></i></a>
          <a href="#"><i class="fab fa-twitter"></i></a>
          <a href="#"><i class="fab fa-instagram"></i></a>
          <a href="#"><i class="fab fa-linkedin-in"></i></a>
        </div>
      </div>
      <div class="link-boxes">
        <ul class="box_desc">
          <li class="link_name">Company</li>
          <p>MSS Consulting, opérateur de la plateforme générique de dématérialisation SPADES, 
            sera votre meilleur partenaire pour le deploiment de vos projets de transformation digitale.</p>
        </ul>
        <div class="block">
            <ul class="box">
            <li class="link_name">Liens Utiles</li>
            <li><a href="/transporteurs"><i class="fas fa-forward" style="color: #f81e25;"></i>&ensp;Annuaire des transporteurs</a></li>
            <li><a href="/devis-client"><i class="fas fa-forward" style="color: #f81e25;"></i>&ensp;Demander devis</a></li>
            <li><a href="/reponse-devis"><i class="fas fa-forward" style="color: #f81e25;"></i>&ensp;Réponse devis</a></li>

            </ul>
            <ul class="box">
                <li class="link_name">Liens Utiles</li>
                <li><a href="/guide"><i class="fas fa-forward" style="color: #f81e25;"></i>&ensp;Guide d'utilisation</a></li>
                <li><a href="/comment-ca-marche"><i class="fas fa-forward" style="color: #f81e25;"></i>&ensp;Comment ça marche</a></li>
                <li><a href="/contact"><i class="fas fa-forward" style="color: #f81e25;"></i>&ensp;Contact</a></li>
            </ul>
        </div>
        <ul class="box input-box">
          <li class="link_name">S'abonner</li>
          <li><input type="text" placeholder="Enter your email"></li>
          <li><input type="button" value="S'abonner"></li>
        </ul>
      </div>
    </div>
    <div class="bottom-details">
      <div class="bottom_text">
        <span class="copyright_text">Copyright © 2024 <a href="#" class="nom_societe">MSS Consulting.</a>All rights reserved</span>
        <span class="policy_terms">
          <a href="#">Privacy policy</a>
          <a href="#">Terms & condition</a>
        </span>
      </div>
    </div>
  </footer>