<!-- Div pour le menu latéral sur mobile -->
<div id="sidebar" class="sidebar">
  <div class="sidebar-header">
   
    <img src="/assets/logo_transp3.png" alt="Logo" class="sidebar-logo">

    <a href="javascript:void(0)" class="close-btn" (click)="closeSidebar()">&times;</a>
  </div>

  <div class="sidebar-content">
    <!-- Liens visibles seulement pour les utilisateurs non connectés -->
    <ng-container *ngIf="!isUserAuthenticated()">
      <a href="/transporteurs"><i class="fa fa-truck"></i> Annuaire des transporteurs</a>
      <a href="/devis-client"><i class="fa fa-file-alt"></i> Demander devis</a>
      <a href="/reponse-devis"><i class="fa fa-reply"></i> Réponse devis</a>
      <a href="/guide"><i class="fa fa-book"></i> Guide d'utilisation</a>
      <a href="/comment-ca-marche"><i class="fa fa-question-circle"></i> Comment ça marche ?</a>
      <a href="/contact"><i class="fa fa-envelope"></i> Contact</a>
      <a href="/register"><i class="fa fa-user-plus"></i> Inscription</a>
      <a href="/login"><i class="fa fa-sign-in-alt"></i> Connexion</a>
    </ng-container>

    <!-- Liens pour les utilisateurs connectés -->
    <ng-container *ngIf="isUserAuthenticated() && User?.role === 'Transporteur'" >
      <p class="sidebar-user">{{ User?.firstName }} {{ User?.lastName }}</p>
      <p class="sidebar-role">{{ User?.role }}</p>
      <a routerLink="/profil"><i class="fa fa-user"></i> Profil</a>
      <a (click)="logout()"><i class="fa fa-sign-out-alt"></i> Déconnexion</a>
      <a routerLink="/home-page"><i class="fa fa-home"></i> Tableau de Bord</a>
      <a routerLink="/message-transporteur"><i class="fa fa-envelope"></i> Messages</a>
      <a routerLink="/list-devis-transporteur"><i class="fa fa-file-alt"></i> Mes demandes de devis</a>
      <a routerLink="/mission-transporteur"><i class="fa fa-tasks"></i> Mes missions</a>
      <a routerLink="/statistique-mission"><i class="fa fa-chart-bar"></i> Statistiques missions</a>
      <a routerLink="/list-chauffeur"><i class="fa fa-users"></i> Chauffeurs</a>
      <a routerLink="/vehicules"><i class="fa fa-car"></i> Véhicules</a>
      <a routerLink="/statistiques-vehicules"><i class="fa fa-chart-line"></i> Statistiques véhicules</a>
    </ng-container>

    <ng-container *ngIf="isUserAuthenticated() && User?.role === 'Admin'" >
      <p class="sidebar-user">{{ User?.firstName }} {{ User?.lastName }}</p>
      <p class="sidebar-role">{{ User?.role }}</p>
      <a routerLink="/profil"><i class="fa fa-user"></i> Profil</a>
      <a (click)="logout()"><i class="fa fa-sign-out-alt"></i> Déconnexion</a>
      <a routerLink="/home-page"><i class="fa fa-home"></i> Tableau de Bord</a>
      <a routerLink="/transporteurs-admin"><i class="fa fa-envelope"></i> Transporteurs </a>
    </ng-container>
    <ng-container *ngIf="isUserAuthenticated() && User?.role === 'Client'" >
      <p class="sidebar-user">{{ User?.firstName }} {{ User?.lastName }}</p>
      <p class="sidebar-role">{{ User?.role }}</p>
      <a routerLink="/profil"><i class="fa fa-user"></i> Profil</a>
      <a (click)="logout()"><i class="fa fa-sign-out-alt"></i> Déconnexion</a>
      <a routerLink="/missions-client"><i class="fa fa-truck"></i> Mes comanndes </a>
    </ng-container>
    <ng-container *ngIf="isUserAuthenticated() && User?.role === 'Chauffeur'" >
      <p class="sidebar-user">{{ User?.firstName }} {{ User?.lastName }}</p>
      <p class="sidebar-role">{{ User?.role }}</p>
      <a routerLink="/profil"><i class="fa fa-user"></i> Profil</a>
      <a (click)="logout()"><i class="fa fa-sign-out-alt"></i> Déconnexion</a>
      <a routerLink="/mission-chauffeur"><i class="fa fa-truck"></i> Mes missions </a>
      <a routerLink="/message-chauffeur"><i class="fa fa-comments-o"></i> Messages </a>
    </ng-container>
  </div>
</div>



<!-- La barre de navigation pour les grands écrans -->
 
 <div class="globale-navbar">
<div class="navi" [ngClass]="{'scrolled': isScrolled}">
  
  <div class="partie1">
    <ul class="groupe-item">
      <li class="nav-item2">
        <a *ngIf="!isUserAuthenticated()"  href="/guide"><i class="fas fa-book"></i>&ensp;Guide d'utilisation</a>
      </li>
      <li class="nav-item2">
        <a *ngIf="!isUserAuthenticated()"  href="/comment-ca-marche">
          <i class="fas fa-question-circle"></i>&ensp;Comment ça marche</a>
      </li>
    </ul>
  </div>
  <div class="partie2">
    <ul class="groupe-item">
      <li class="nav-item2">
        <a *ngIf="!isUserAuthenticated()"  href="/guide"><i class="fas fa-phone"></i>&ensp;(+216) 98 35 93 58</a>
      </li>
      <li class="nav-item2">
        <a *ngIf="!isUserAuthenticated()"  href="/comment-ca-marche">
          <i class="fas fa-envelope"></i>&ensp;karim.gharbi@mss.tn</a>
      </li>

    </ul>
  </div> 
  <div class="partie3">
    <ul class="groupe-item">
    <li class="nav-item2">
      <a *ngIf="!isUserAuthenticated()"  href="#"><i class="fab fa-facebook"></i></a>
    </li>
    <li class="nav-item2">
      <a *ngIf="!isUserAuthenticated()"  href="#">
        <i class="fab fa-linkedin"></i></a>
    </li>
    <li class="nav-item2">
      <a *ngIf="!isUserAuthenticated()"  href="#">
        <i class="fab fa-twitter"></i></a>
    </li>
    <li class="nav-item2">
      <a *ngIf="!isUserAuthenticated()"  href="#">
         </a>
    </li>
  </ul>
  </div>
</div>

<div class="custom-box custom-box-small" >
  <div class="mobile">
  <button class="open-btn d-lg-none" (click)="openSidebar()">&#9776; Menu</button>
</div>

  <nav class="navbar navbar-expand-lg navbar-light" >
    <div class="container-fluid d-flex align-items-center justify-content-between">
      <a class="navbar-brand" href="">
        <img src="/assets/logo_transp2.png" alt="Logo" class="logo">
      </a>
      <!-- Ajoutez un bouton pour ouvrir le sidebar sur mobile -->

          <ul class="navbar-nav">
        <li class="nav-item">
          <a *ngIf="!isUserAuthenticated()" class="nav-link" href="/transporteurs">Annuaire des transporteurs</a>
        </li>
        <li class="nav-item">
          <a *ngIf="!isUserAuthenticated()" class="nav-link" href="/devis-client">Demander devis</a>
        </li>
        <li class="nav-item">
          <a *ngIf="!isUserAuthenticated()" class="nav-link" href="/reponse-devis">Réponse devis</a>
        </li>
        <li class="nav-item">
          <a *ngIf="!isUserAuthenticated()" class="nav-link" href="/contact">Contact</a>
        </li>
        <!-- Dropdown pour Connexion et Inscription -->
<!--
        <li class="nav-item dropdown" *ngIf="!isUserAuthenticated()">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Actions
          </a>
          
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><a class="dropdown-item" href="/register">Inscription</a></li>
            <li><a class="dropdown-item" href="/login">Connexion</a></li>
          </ul>
        </li>
-->
      </ul>
      <div class="authentification">
        <a class="btn-insc"  href="/register">inscription</a>
        <a class="btn-conex" href="/login">connexion</a>
      </div>
      <div class="navbar-right"  *ngIf="isUserAuthenticated()">
        <!-- Formulaire de recherche -->
        <form class="search-form">
          <input class="search-input" type="search" placeholder="Rechercher..." aria-label="Search">
          <button class="search-btn" type="submit">
            <i class="fa fa-search"></i>
          </button>
        </form>
      
       
      
        <!-- Bouton de notification -->
        <a class="notification-btn" href="#">
          <i class="fa fa-bell"></i>
          <span class="badge">3</span>
        </a>
      
        <!-- Sélecteur de langue -->
        <div class="dropdown language-dropdown">
          <a class="language-btn" id="languageMenu" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-globe"></i>
            <span>Langue</span>
            <i class="fa fa-chevron-down"></i>
          </a>
          <ul class="dropdown-menu language-menu" aria-labelledby="languageMenu">
            <li><a class="dropdown-item" href="#">Français</a></li>
            <li><a class="dropdown-item" href="#">Anglais</a></li>
          </ul>
        </div>
       <!-- Icône de profil utilisateur avec menu déroulant -->
       <div class="dropdown profile-dropdown">
        <a class="profile-btn" id="userMenu" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          <img src="/assets/profile-avatar.png" alt="User Profile" class="profile-icon"> <!-- Icône de profil -->
          <span>Mon Profil</span>
          <i class="fa fa-chevron-down"></i>
        </a>
        <ul class="dropdown-menu profile-menu" aria-labelledby="userMenu">
          <li><a class="dropdown-item" href="/profil">Mon Profil</a></li>
          <li><a class="dropdown-item" href="/home-page">Tableau de bord</a></li>
          <li><hr class="dropdown-divider"></li>
          <li><a class="dropdown-item" href="#" (click)="logout()">Déconnexion</a></li>
        </ul>
      </div>
      </div> 
    </div>
  </nav>
  <!--<div class="blue-bar"></div>-->
</div>

</div>