import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/Services/user-service.service';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { HttpClient } from '@angular/common/http'; // Pour faire l'appel HTTP
import Swal from 'sweetalert2'; // Importer SweetAlert2
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-list-transporteurs-admin',
  templateUrl: './list-transporteurs-admin.component.html',
  styleUrls: ['./list-transporteurs-admin.component.css']
})
export class ListTransporteursAdminComponent implements OnInit {
  users: any[] = [];
  searchText='';

  
  page: number = 1;
  totalLength: number = 0;

  private UrlMonTransporteur = environment.URL_MON_TRANSPORTEUR;
  private UrlGestionUser = environment.URL_GESTION_USER;
  
  constructor(private userService: UserService, private http: HttpClient) {}

  ngOnInit(): void {
    this.userService.getUsers().subscribe(
      (data) => {
        this.users = data.filter(user => user.role === 'Transporteur');
        console.log('Users fetched successfully', this.users);
      },
      (error) => {
        console.error('Error fetching users', error);
      }
    );
  }

  exportToExcel(): void {
    const exportData = this.users.map(user => ({
      firstName: user.firstName, 
      lastName: user.lastName,
      userName: user.userName,
      password: user.password,
      email: user.email,
      phone: user.phoneNumber
    }));

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportData);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Users');

    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, 'users_data');
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    saveAs(data, `${fileName}_${new Date().getTime()}.xlsx`);
  }

  // Nouvelle méthode pour ajouter un transporteur
  addTransporteur(user: any): void {
    const transporteurData = {
      firstName: user.firstName,
      lastName: user.lastName,
      userName: user.userName,
      password: user.password,
      email: user.email,
      phone: user.phoneNumber,
      rne: user.rne,
      profil: "Carrier",
      protocolEchange: "EDI",
      url:  this.UrlMonTransporteur
    };

    console.log("transporteurData : ", transporteurData)

    // Appel POST pour ajouter le transporteur
    this.http.post(this.UrlGestionUser, transporteurData)
      .subscribe(
        (response) => {
          // Afficher une alerte SweetAlert2 en cas de succès
          Swal.fire({
            title: 'Succès',
            text: 'Le transporteur a été ajouté avec succès.',
            icon: 'success',
            confirmButtonText: 'OK'
          });
        },
        (error) => {
          console.error('Erreur lors de l\'ajout du transporteur', error);
          // Afficher une alerte en cas d'erreur
          Swal.fire({
            title: 'Erreur',
            text: 'Une erreur est survenue lors de l\'ajout du transporteur.',
            icon: 'error',
            confirmButtonText: 'OK'
          });
        }
      );
  }
}

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
