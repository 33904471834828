<div class="container mt-4">
  <h1 style="margin-top:2rem; margin-bottom: 2rem; text-align: center;font-family: 'Open Sans',sans-serif; font-weight: 900; color: #002e51;">Liste des transporteurs</h1>
  <p style="color: gray;  text-align: center;">
    Dans cette interface, vous pouvez rechercher des transporteurs en fonction de leur emplacement et d'autres critères. Pour chaque transporteur, vous pourrez consulter une fiche détaillée affichant des informations telles que l'âge des véhicules et le nombre de transporteurs disponibles.
  </p>
  <hr>

  <div class="card custom-card">
    <div class="card-body">  <div class="row align-items-center mb-3">
      <div class="col-md-4">
        <input type="text" [(ngModel)]="searchText" class="form-control search-input" placeholder="Sansearch..." />
      </div>
      <div class="col-md-4">
        <select [(ngModel)]="selectedCityCode" (change)="filterUsers()" class="form-control">
          <option value="">-- Sélectionner une ville et code postal --</option>
          <option *ngFor="let cityCode of cityCodes" [value]="cityCode.codePostal + ', ' + cityCode.ville">
            {{ cityCode.ville }} ({{ cityCode.codePostal }})
          </option>
        </select>
      </div>
      <div class="col-md-4">
        <select [(ngModel)]="selectedVehicle" (change)="filterUsers()" class="form-control">
          <option value="">-- Sélectionner un véhicule --</option>
          <option *ngFor="let vehicle of vehicles" [value]="vehicle.immatriculation">
            {{ vehicle.categorie }}
          </option>
        </select>
      </div>
    </div>
  
    <div class="text-end mb-3">
      Total d'éléments : <span class="badge badge-pill badge-primary">{{ filteredUsers.length }}</span> 
    </div>
  
    <div class="row">
      <div class="col-md-4 mb-4" *ngFor="let user of filteredUsers | paginate: { itemsPerPage: 6, currentPage: page, totalItems: totalLength } | filter:searchText">
        <div class="card h-100 shadow-sm">
          <div class="card-body">
            <h5 class="card-title">{{ user.firstName }} {{ user.lastName }}</h5>
            <hr>
            <p class="card-text"><strong>Email:</strong> {{ user.email }}</p>
            <p class="card-text"><strong>Numéro de téléphone:</strong> {{ user.phoneNumber }}</p>
            <p class="card-text"><strong>Address:</strong> {{ user.address }}</p>
            <p class="card-text"><strong>Ville :</strong> {{ user.city }}</p>
            <button class="btn btn-primary btn-sm me-3" (click)="showTransporteurDetails(user)">
              <i class="fa fa-id-card-o me-2" aria-hidden="true"></i> Voir détails
            </button>
            <button class="btn btn-primary btn-sm" [routerLink]="['/devis-form-client-transporteur-selected', user.id]">
              <i class="fa fa-location-arrow me-2" aria-hidden="true"></i> Demander Devis
            </button>
          </div>
        </div>
      </div>
    </div>
    
  
    <div class="d-flex justify-content-center mt-3">
      <pagination-controls (pageChange)="page = $event"></pagination-controls>
    </div>
  </div>
 </div>
</div>

