<app-navbar></app-navbar>
<div>
  <app-sidebar *ngIf="isUserAuthenticated()" class="sidebar"></app-sidebar>
  <div [ngClass]="{'main-content': isUserAuthenticated()}">
    <div>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
<app-footer *ngIf="!isUserAuthenticated()"></app-footer>
