import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/Services/user-service.service';
import { VehiculeService } from 'src/app/Services/vehicule.service';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
})
export class HomePageComponent implements OnInit {
 

  userId: string | null = null;
  chauffeursList: any[] = [];
  totalVehicules: number = 0;
  vehiculesDisponibles: number = 0;
  vehiculesNonDisponibles: number = 0; 
  vehicules : any[] | undefined;
  totalMissions: number = 0;
  totalMissionsByStatus: { [key: string]: number } = {};
  missions: any[] = [];

  private missionsApiUrl = environment.APP_API_URL + "/api/missions";
  chauffeurPerformance: {
    successfulDeliveries: number;
    delays: number;
    complaints: number;
  };

  reportsGenerated: number;
  customerSatisfaction: string;
  feedbackCount: number;




  recentNotifications: { message: string; date: string }[] = [];

  pendingTasks = [
    { title: 'Vérifier les stocks', priority: 'Haute', deadline: '2024-09-04' },
    { title: 'Planifier l’entretien', priority: 'Moyenne', deadline: '2024-09-06' },
    { title: 'Envoyer les factures', priority: 'Basse', deadline: '2024-09-07' }
  ];
 
  constructor(private userService: UserService,private  vehiculeService: VehiculeService, private http: HttpClient) { 
     // Initialisation des valeurs par défaut
     this.chauffeurPerformance = {
      successfulDeliveries: 0,
      delays: 0,
      complaints: 0
    };
    this.reportsGenerated = 0;
    this.customerSatisfaction = 'N/A';
    this.feedbackCount = 0;
  }

  ngOnInit(): void {

    this.userId = this.isUserAuthenticated();
    this.loadChauffeurs();
    this.loadVehicules();
    this.loadRecentNotifications(); 
    // Exemple de données simulées
    this.chauffeurPerformance = {
      successfulDeliveries: 120,
      delays: 15,
      complaints: 5
    };

    this.reportsGenerated = 8;
    this.customerSatisfaction = '85%';
    this.feedbackCount = 42;
    this.loadMissions();
  }

  generateReport(): void {
    // Logique pour générer un rapport
    alert('Génération d\'un rapport...');
    // Ajoutez ici la logique nécessaire pour générer le rapport
  }

  viewCustomerFeedback(): void {
    // Logique pour visualiser les retours des clients
    console.log('Visualisation des retours clients...');
    // Ajoutez ici la logique nécessaire pour afficher les retours clients
  }

  // Vérifier si l'utilisateur est authentifié
  isUserAuthenticated(): string | null {
    return localStorage.getItem('idUser');
  }   
  loadChauffeurs(): void {
    this.userService.getChauffeurs().subscribe(
      (data: any[]) => {
        if (this.userId !== null) {
          const userIdNumber = parseInt(this.userId, 10);
          this.chauffeursList = data.filter(item => item.transporteurId === userIdNumber).map(chauffeur => ({
            image: chauffeur.image ? chauffeur.image.picByte : null, 
            nom: chauffeur.lastName,
            prenom: chauffeur.firstName,
            statut: chauffeur.status || 'Actif' // Utilisation d'un statut par défaut si null
          }));
        } else {
          this.chauffeursList = [];
        }
      },
      error => {
        console.error('Erreur lors de la récupération des chauffeurs :', error);
      }
    );
  }

  loadVehicules(): void {
    this.vehiculeService.getVehicules().subscribe(
      (data: any[]) => {
        if (this.userId !== null) {
          const userIdNumber = parseInt(this.userId, 10);
  
          // Filtrer les véhicules par transporteur ID
          const vehiculesUtilisateur = data.filter(item => item.transporteurId === userIdNumber);
  
          // Calculer les statistiques
          this.totalVehicules = vehiculesUtilisateur.length; // Nombre total
          this.vehiculesDisponibles = vehiculesUtilisateur.filter(item => item.statut === "Disponible").length; // Disponibles
          this.vehiculesNonDisponibles = this.totalVehicules - this.vehiculesDisponibles; // Non disponibles
  
          // Affecter les véhicules disponibles à la liste
          this.vehicules = vehiculesUtilisateur.filter(item => item.statut === "Disponible");
        } else {
          // Réinitialiser les variables si l'utilisateur est nul
          this.vehicules = [];
          this.totalVehicules = 0;
          this.vehiculesDisponibles = 0;
          this.vehiculesNonDisponibles = 0;
        }
      },
      error => {
        console.error('Erreur lors de la récupération des véhicules :', error);
      }
    );
  }

  loadMissions(): void {
    const idTranspoteur = Number(localStorage.getItem('idUser')); // Récupérer l'ID du transporteur depuis localStorage
  
    this.http.get<any[]>(this.missionsApiUrl).pipe(
      // Filtrer les missions par transporteur
      map((missions) => missions.filter(mission => mission.idTranspoteur === idTranspoteur)),
      
      // Trier par date de début
      map((filteredMissions) => 
        filteredMissions.sort((a, b) => new Date(a.dateDebut).getTime() - new Date(b.dateDebut).getTime())
      ),
  
      // Ne garder que les 5 premières missions
      map((sortedMissions) => sortedMissions.slice(0, 3))
    ).subscribe(
      (topMissions) => {
        this.missions = topMissions; // Mettre à jour la liste des missions dans le composant
        console.log(this.missions);
        this.totalMissions = this.missions.length; // Mettre à jour le nombre total de missions
        this.calculateMissionStatistics(this.missions); // Calculer les statistiques des missions par statut
      },
      error => {
        console.error('Erreur lors de la récupération des missions :', error);
      }
    );
  }
  calculateMissionStatistics(missions: any[]): void {
    // Réinitialiser les compteurs de statuts
    this.totalMissionsByStatus = {
      'Prise en charge marchandise': 0,
      'Marchandise livrée': 0,
      'Revenu au parking': 0,
      'Mission démarrée': 0,
      'Mission créée': 0,
      'Retourner au parking': 0
    };

    // Compter le nombre de missions pour chaque statut
    missions.forEach(mission => {
      if (this.totalMissionsByStatus[mission.statut] !== undefined) {
        this.totalMissionsByStatus[mission.statut]++;
      }
    });
  }
// Charger uniquement les dernières notifications
loadRecentNotifications(): void {
  const recentNotifications: { message: string; date: string }[] = [];

  // Charger le dernier chauffeur ajouté
  this.userService.getChauffeurs().subscribe(
    (chauffeurs: any[]) => {
      if (this.userId !== null) {
        const userIdNumber = parseInt(this.userId, 10);
        const lastChauffeur = chauffeurs
          .filter(chauffeur => chauffeur.transporteurId === userIdNumber)
          .sort((a, b) => new Date(b.dateAdded).getTime() - new Date(a.dateAdded).getTime())[0]; // Tri et sélection du plus récent

        if (lastChauffeur) {
          recentNotifications.push({
            message: `Nouveau chauffeur ajouté : ${lastChauffeur.firstName} ${lastChauffeur.lastName}`,
            date: new Date().toISOString().split('T')[0] // Date actuelle
          });
        }
        this.recentNotifications = [...recentNotifications];
      }
    },
    error => {
      console.error('Erreur lors de la récupération des chauffeurs :', error);
    }
  );

  // Charger la dernière mission créée
  const idTranspoteur = Number(localStorage.getItem('idUser'));
  this.http.get<any[]>(this.missionsApiUrl).subscribe(
    (missions: any[]) => {
      const lastMission = missions
        .filter(mission => mission.idTranspoteur === idTranspoteur)
        .sort((a, b) => new Date(b.dateDebut).getTime() - new Date(a.dateDebut).getTime())[0]; // Tri et sélection du plus récent

      if (lastMission) {
        recentNotifications.push({
          message: `Nouvelle mission créée : ${lastMission.description || 'Mission'}`,
          date: lastMission.dateDebut
        });
      }

      // Mettre à jour les notifications récentes
      this.recentNotifications = [...recentNotifications];
    },
    error => {
      console.error('Erreur lors de la récupération des missions :', error);
    }
  );

  // Charger le dernier véhicule ajouté
  this.vehiculeService.getVehicules().subscribe(
    (vehicules: any[]) => {
      if (this.userId !== null) {
        const userIdNumber = parseInt(this.userId, 10);
        const lastVehicule = vehicules
          .filter(vehicule => vehicule.transporteurId === userIdNumber)
          .sort((a, b) => new Date(b.dateAdded).getTime() - new Date(a.dateAdded).getTime())[0]; // Tri et sélection du plus récent

        if (lastVehicule) {
          recentNotifications.push({
            message: `Nouveau véhicule ajouté : ${lastVehicule.marque || 'Véhicule'} ${lastVehicule.modele || ''}`,
            date: new Date().toISOString().split('T')[0] // Date actuelle
          });
        }
        this.recentNotifications = [...recentNotifications];
      }
    },
    error => {
      console.error('Erreur lors de la récupération des véhicules :', error);
    }
  );
}

}
