import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.css']
})
export class TestComponent implements OnInit {

  totalMissions: number = 0;
  totalMissionsByStatus: { [key: string]: number } = {};
  missions : any [] | undefined; 
  private missionsApiUrl = environment.APP_API_URL + "/api/missions";


  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.loadMissions(); // Appel pour charger les missions au démarrage
  }
/*
  loadMissions(): void {
    const idTranspoteur = Number(localStorage.getItem('idUser')); // Récupérer l'ID du transporteur depuis localStorage

    this.http.get<any[]>(this.missionsApiUrl).pipe(
      map((missions) => missions.filter(mission => mission.idTranspoteur === idTranspoteur)), // Filtrer les missions par transporteur
      map((filteredMissions) => {
        this.totalMissions = filteredMissions.length; // Calculer le nombre total de missions
        this.calculateMissionStatistics(filteredMissions); // Calculer les statistiques des missions par statut
      })
    ).subscribe(
      () => {},
      error => {
        console.error('Erreur lors de la récupération des missions :', error);
      }
    );
  }
*/

loadMissions(): void {
  const idTranspoteur = Number(localStorage.getItem('idUser')); // Récupérer l'ID du transporteur depuis localStorage

  this.http.get<any[]>(this.missionsApiUrl).pipe(
    // Filtrer les missions par transporteur
    map((missions) => missions.filter(mission => mission.idTranspoteur === idTranspoteur)),
    
    // Trier par date de début
    map((filteredMissions) => 
      filteredMissions.sort((a, b) => new Date(a.dateDebut).getTime() - new Date(b.dateDebut).getTime())
    ),

    // Ne garder que les 5 premières missions
    map((sortedMissions) => sortedMissions.slice(0, 5))
  ).subscribe(
    (topMissions) => {
      this.missions = topMissions; // Mettre à jour la liste des missions dans le composant
      console.log(this.missions);
      this.totalMissions = this.missions.length; // Mettre à jour le nombre total de missions
      this.calculateMissionStatistics(this.missions); // Calculer les statistiques des missions par statut
    },
    error => {
      console.error('Erreur lors de la récupération des missions :', error);
    }
  );
}

  calculateMissionStatistics(missions: any[]): void {
    // Réinitialiser les compteurs de statuts
    this.totalMissionsByStatus = {
      'Prise en charge marchandise': 0,
      'Marchandise livrée': 0,
      'Revenu au parking': 0,
      'Mission démarrée': 0,
      'Mission créée': 0,
      'Retourner au parking': 0
    };

    // Compter le nombre de missions pour chaque statut
    missions.forEach(mission => {
      if (this.totalMissionsByStatus[mission.statut] !== undefined) {
        this.totalMissionsByStatus[mission.statut]++;
      }
    });
  }
}
